<template>
  <div class="term--service-wrapper privacy--policy-wrapper">
    <banner :banner-text="bannerText" />
    <div class="row">
      <div class="col-12">
        <div class="opening" v-html="$t('privacy.opening')"></div>
      </div>
    </div>
    <div class="intro--wrapper">
      <div class="introduction" v-html="$t('privacy.contentIntro')"></div>
    </div>
    <div class="content--wrapper">
      <informasi-pengguna></informasi-pengguna>
      <penggunaan-informasi></penggunaan-informasi>
      <cookies></cookies>
      <merk-dagang-belirumah></merk-dagang-belirumah>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/utils/page-banner';
import InformasiPengguna from '@/components/static-page/privacy-policy/informasi-pengguna';
import PenggunaanInformasi from '@/components/static-page/privacy-policy/penggunaan-informasi';
import Cookies from '@/components/static-page/privacy-policy/cookies';
import MerkDagangBelirumah from '@/components/static-page/privacy-policy/merk-dagang-beliruma';
export default {
  name: 'privacy-policy',
  components: {
    Banner,
    InformasiPengguna,
    PenggunaanInformasi,
    Cookies,
    MerkDagangBelirumah,
  },
  computed: {
    bannerText() {
      return this.$t('privacy.title');
    },
  },
};
</script>
